<template>
	<div>
		<div class="mh100 bg-white boderra8 box-shadow0010">
			<div class="h54 align-items-center">
				<div class="title-item center fz16 cure-pointer h100"
					:class="titleIndex == i ? 'co-010085' : 'co-333333'" v-for="(item, i) in titleList" :key="i"
					@click="titleChoose(i)">{{item}}</div>
			</div>
			<div class="mart4 paddlr30 flex-wrap" v-if="titleIndex==0">
				<div class="list-item padd20 display-flex marr20 marb20"   v-for="(item, i) in list" :key="i"
					@click="toDetail(item.id)"  >
					  <el-image class="display-block img_02 boderra50" :src="item.avatar" fit="cover"></el-image>
					<div class="flex-1 marl20">
						<div class="fz20 co-333333 font-blod collect-name two-point" >{{item.name}}</div>
						<div class="fz14 co-333333 mart6">{{item.school}}</div>
						<div class="flex-wrap mart16" v-if="item.profession">
							<div class="label-item center co-32B16C fz16 l16" v-for=" (vo,idx) in  item.profession.slice(0, 3)" :key='idx'  >{{vo}}</div>
						</div>
					</div>
				</div>		
			</div>
			<div class="mart4 paddlr30 flex-wrap" v-if="titleIndex==1">
				<div class="list-item padd20 display-flex marr20 marb20"  v-for="(item, i) in list" :key="i" @click="toDetail(item.id)" >
					  <el-image class="display-block img_02 boderra50" :src="item.image" fit="cover"></el-image>
					<div class="flex-1 marl20">
						<div class="fz20 co-333333 font-blod collect-name two-point" >
							<span class="">{{item.title}}</span>
							
						</div>			
						<div class="fz14 co-999999 mart20">
							<span class="marr20">{{item.favorite}}推荐</span>
							<span>{{item.sign_num||'0'}}报名</span>
						</div>
					</div>
				</div>		
			</div>
			<loadMore :state="state" loading_icon></loadMore>
		</div>

		<div class="center paddt54 paddb15">
			<el-pagination background  class="center "  @current-change="handleCurrentChange"
			    layout="prev, pager, next, jumper" :current-page="page"   :total="maxnum" :page-size="size">
			</el-pagination>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'MyFollow',
		data() {
			return {
				titleList: ['名师', '机构'], // 收藏导航
				titleIndex: 0, // 导航选中
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				dataType:'teacher_collect',
				size: 10, 
				maxnum:'' ,//总条数
			}
		},
	mounted() {
		
		this.getData()
	
	},
	methods: {
		getData() {
			if (this.loading || this.state > 1) return;
			let page = parseInt(this.page) + 1;
			this.loading = true;
			this.state = 1;
			let size = 10;
			this.$req({
				method: 'get',
				data: {
					type: this.dataType,
					page: this.page,
					size: size,
				},
				url: '/api/mapleCollection/get',
				success: res => {
					let list = res;
					console.log(list)
					this.list = list;
					if (list.length == size) {
						this.state = 1;
					} else if (list.length > 0) {
						this.state = 2;
					} else if (this.list.length == 0) {
						this.state = 3;
					}
					this.loading = false;
				},
				fail: error => {

				}
			});
			
			this.$req({
				method: 'get',
				data: {
					type: this.dataType,
					show_page:1,
					
				},
				url: '/api/mapleCollection/get',
				success: res => {
					console.log(res,'总条数')
					this.maxnum =res;
				},
				fail: error => {
					
				}
			});
		},
			// 导航选中
			titleChoose(e) {
					if (this.titleIndex == e) return
					this.titleIndex = e
					if (e == 0) {
						this.dataType = 'teacher_collect'
					} else if (e == 1) {
						this.dataType = 'agency_collect'
					}
					this.list = [];
					this.page = 0;
					this.state = 0;
					this.loading = false;
					this.getData();

				},
				// 跳转详情
				toDetail(id) {
					if (this.titleIndex == 0) {
						// this.$router.push({
						// 	path: '/TeacherDetail/TeacherDetail'
						// })
						this.$router.push({
							path: '/TeacherDetail',
							query: {
								id: id
							}
						})
					} else {
						this.$router.push({
							path: '/SincerityDetail',
							query: {				
								id: id
							}
						})
					}
				},
				handleCurrentChange(val) {
				   this.page = val;
				   this.list = [];
				   this.loading = false;
				   this.getlist();
				    document.documentElement.scrollTop = document.body.scrollTop = 400;
				},
		}
	}
</script>

<style lang="less" scoped>
	.title-item {
		padding: 0 30px;
	}

	.list-item {
		width: 408px;
		border: 1px solid #E6E6E6;
		border-radius: 8px;

		.img_02 {
			width: 78px;
			height: 78px;
		}
	}

	.list-item:nth-child(2n) {
		margin-right: 0;
	}

	.list-item:hover {
		border: 1px solid #DCFF03;
		box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);
		border-radius: 8px;
		cursor: pointer;
	}

	.list-item:hover .collect-name {
		color: #010085;
	}

	.label-item {
		padding: 0 10px;
		height: 28px;
		background: rgba(50, 177, 108, 0.12);
		border-radius: 4px;
		border: 0;
	}

	// 改变组件样式
	.el-pager li {
		font-size: 14px !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
		line-height: 40px !important;
		padding: 0 !important;
		color: #999999 !important;
	}

	.btn-prev,
	.btn-next {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
	}

	.el-pager .active {
		background: #DCFF03 !important;
		color: #010085 !important;
		border: 1px solid #DCFF03 !important;
	}
</style>
